<template>
    <div class="hello">
        <h1 @click="$goTo('sell-1')">UserProfile Page 2</h1>
    </div>
</template>

<script>
  export default {
    name: 'HelloWorld',
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
